<template>
  <div>
    <vs-popup class="popup80 noOverflow" :fullscreen="false" :title="showPdfTitle" :active.sync="popupShowPdf">
      <object :data="srcPdf" width="100%" style="height: 600px" />
    </vs-popup>

    <vs-popup class="popup90" :fullscreen="false" title="Caixas" :active.sync="localShow">
      <vs-table v-if="cashiers.length" :data="cashiers" hoverFlat stripe style="overflow: -webkit-paged-y">
        <template slot="thead">
          <vs-th>Abertura</vs-th>
          <vs-th>Saldo Abertura</vs-th>
          <vs-th>Fechamento</vs-th>
          <vs-th>Saldo Fechamento</vs-th>
          <vs-th>Responsável</vs-th>
          <vs-th>Ações</vs-th>
        </template>
        <template slot-scope="{data}">
          <vs-tr :key="i" v-for="(cashier, i) in data" style="opacity: 1 !important">
            <vs-td>{{ formatDateTime(cashier.opening_date) }}h</vs-td>
            <vs-td>{{ cashier.opening_balance.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</vs-td>
            <vs-td>{{ formatDateTime(cashier.closed_date) }}</vs-td>
            <vs-td>{{ cashier.final_balance.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</vs-td>
            <vs-td>{{ cashier.user.name }}</vs-td>
            <vs-td>
              <!-- IMPRIMIR CAIXA -->
              <vx-tooltip text="Imprimir Relatório de Caixa" position="top" class="h-5 w-5 ml-2 inline-flex">
                <feather-icon icon="PrinterIcon" svgClasses="h-5 w-5 hover:text-primary cursor-pointer" @click="print(cashier)" />
              </vx-tooltip>
              <!-- VER -->

<!-- ESSA OPÇÃO DARIA PARA REABRIR OU NAO (NAO RECORDO) CAIXAS DE OUTROS PERÍODOS E FAZER ALTERAÇÕES PORÉM DARIA MUITA CONFUSÃO
DEVE SER ALGO MUITO BEM OPENSADO POR ISSO POR HORA FICARÁ DE FORA. CASO SEJA NECESSÁRIO REAJUSTE RETROATIVO BASTA FAZER PAGAMENTOS RETROATIVOS
QUE OS CAIXAS SE AJUSTAM  -->

              <!-- <vx-tooltip text="Ver Detalhes" position="top" class="h-5 w-5 ml-2 inline-flex">
                <feather-icon icon="EyeIcon" @click="see(cashier)" svgClasses="h-5 w-5 hover:text-primary cursor-pointer" />
              </vx-tooltip> -->
            </vs-td>

            <!-- <vs-td>
              <feather-icon title="Imprimir Relatório de Caixa" icon="PrinterIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="print" />
              <feather-icon title="Ver" icon="SeeIcon" svgClasses="h-5 w-5 hover:text-primary cursor-pointer" @click="see" />
            </vs-td> -->
          </vs-tr>

          <vs-tr style="opacity: 1 !important">
            <vs-td colspan="6">
              <vs-divider></vs-divider>
              <div class="vx-col w-full">
                <div class="mt-2 flex flex-wrap items-center">
                  <vs-button class="ml-auto mb-2" color="secondary" @click="cancel">Fechar</vs-button>
                </div>
              </div>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>

      <vs-alert v-if="!cashiers || !cashiers.length" class="mt-4 mb-4" :active="true" color="warning" icon-pack="feather" icon="icon-info" style="height: auto">
        <span>Nenhuma parcela em aberto para o período selecionado!</span>
      </vs-alert>
    </vs-popup>
  </div>
</template>

<script>
// Store Module
import moduleMyCashier from '@/store/my-cashier/moduleMyCashier.js'

import { CurrencyInput } from 'vue-currency-input'
const vSelect = () => import(/* webpackChunkName: "vSelect" */ 'vue-select')

export default {
  components: {
    CurrencyInput,
    vSelect
  },
  props: {
    show: {
      type: Boolean, default: false
    },
    date: {
      type: String
    }
  },

  computed: {
    cashiers () {
      return this.$store.state.myCashier.cashiers
    }
  },
  watch: {
    show (val) {
      this.localShow = val
    },
    localShow (val) {
      if (!val) {
        this.$emit('cancel')
      } else {
        this.$vs.loading()
        this.dispatchFilterDate()
      }
    }
  },
  data () {
    return {
      localShow: false,
      showPdfTitle: 'PDF',
      srcPdf: '',
      popupShowPdf: false
    }
  },
  methods: {
    cancel () {
      this.$emit('cancel')
    },
    dispatchFilterDate () {
      this.$vs.loading()
      this.$store.dispatch('myCashier/fetchCashiers', { firstPeriod: this.date, lastPeriod: this.date })
        .then(() => {
          this.$vs.loading.close()
        }).catch(err => {
          this.$vs.loading.close()
          console.error(err)
        })
    },
    formatDateTime (dateTime) {
      if (!dateTime) return ''
      const [YYYY, MM, DD] = (dateTime.slice(0, 10)).split('-')
      const [H, m, s] = (dateTime.slice(11, 19)).split(':')
      return `${DD}/${MM}/${YYYY} ${H}:${m}:${s}`
    },
    async print (cashier, type = 'PDF') {
      this.$vs.loading()
      this.showPdfTitle = `Posição de Caixa [${this.formatDateTime(cashier.opening_date)} - ${this.formatDateTime(cashier.closed_date)}]`
      const resp = await this.$http.post(`${process.env.VUE_APP_API_URL}/cashier/report`, {
        name: `Posição de Caixa [${this.formatDateTime(cashier.opening_date)} - ${this.formatDateTime(cashier.closed_date)}]`,
        file_name: 'Posição de Caixa',
        cashier_id: cashier.id,
        format: 'pdf_paisagem'
      }, { responseType: type === 'EXCEL' ? 'arraybuffer' : null })

      if (type === 'PDF') {
        this.srcPdf = `data:application/pdf;base64,${resp.data}`
        this.popupShowPdf = true
      } else if (type === 'EXCEL') {
        const blob = new Blob([resp.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        this.makeBlobDoc(blob, this.showPdfTitle)
      }
      this.$nextTick(() => { this.$vs.loading.close() })
    },
    see (cashier) {
      this.$emit('see', cashier)
      this.$emit('cancel')
    }
  },
  created () {
    if (!moduleMyCashier.isRegistered) {
      this.$store.registerModule('myCashier', moduleMyCashier)
      moduleMyCashier.isRegistered = true
    }
  }
}
</script>

<style>
.popup90 .vs-popup {
  width: 90% !important;
}
.popup80 .vs-popup {
  width: 80% !important;
}
.noOverflow .vs-popup--content {
  overflow: hidden;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table .tr-values .vs-table--td  {
  padding: 1px 14px;
}
.vs-tooltip {
  z-index: 99999;
}
</style>
