var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "vs-popup",
        {
          staticClass: "popup80 noOverflow",
          attrs: {
            fullscreen: false,
            title: _vm.showPdfTitle,
            active: _vm.popupShowPdf,
          },
          on: {
            "update:active": function ($event) {
              _vm.popupShowPdf = $event
            },
          },
        },
        [
          _c("object", {
            staticStyle: { height: "600px" },
            attrs: { data: _vm.srcPdf, width: "100%" },
          }),
        ]
      ),
      _c(
        "vs-popup",
        {
          staticClass: "popup90",
          attrs: { fullscreen: false, title: "Caixas", active: _vm.localShow },
          on: {
            "update:active": function ($event) {
              _vm.localShow = $event
            },
          },
        },
        [
          _vm.cashiers.length
            ? _c(
                "vs-table",
                {
                  staticStyle: { overflow: "-webkit-paged-y" },
                  attrs: { data: _vm.cashiers, hoverFlat: "", stripe: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function ({ data }) {
                          return [
                            _vm._l(data, function (cashier, i) {
                              return _c(
                                "vs-tr",
                                {
                                  key: i,
                                  staticStyle: { opacity: "1 !important" },
                                },
                                [
                                  _c("vs-td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.formatDateTime(cashier.opening_date)
                                      ) + "h"
                                    ),
                                  ]),
                                  _c("vs-td", [
                                    _vm._v(
                                      _vm._s(
                                        cashier.opening_balance.toLocaleString(
                                          "pt-BR",
                                          { style: "currency", currency: "BRL" }
                                        )
                                      )
                                    ),
                                  ]),
                                  _c("vs-td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.formatDateTime(cashier.closed_date)
                                      )
                                    ),
                                  ]),
                                  _c("vs-td", [
                                    _vm._v(
                                      _vm._s(
                                        cashier.final_balance.toLocaleString(
                                          "pt-BR",
                                          { style: "currency", currency: "BRL" }
                                        )
                                      )
                                    ),
                                  ]),
                                  _c("vs-td", [
                                    _vm._v(_vm._s(cashier.user.name)),
                                  ]),
                                  _c(
                                    "vs-td",
                                    [
                                      _c(
                                        "vx-tooltip",
                                        {
                                          staticClass:
                                            "h-5 w-5 ml-2 inline-flex",
                                          attrs: {
                                            text: "Imprimir Relatório de Caixa",
                                            position: "top",
                                          },
                                        },
                                        [
                                          _c("feather-icon", {
                                            attrs: {
                                              icon: "PrinterIcon",
                                              svgClasses:
                                                "h-5 w-5 hover:text-primary cursor-pointer",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.print(cashier)
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }),
                            _c(
                              "vs-tr",
                              { staticStyle: { opacity: "1 !important" } },
                              [
                                _c(
                                  "vs-td",
                                  { attrs: { colspan: "6" } },
                                  [
                                    _c("vs-divider"),
                                    _c(
                                      "div",
                                      { staticClass: "vx-col w-full" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "mt-2 flex flex-wrap items-center",
                                          },
                                          [
                                            _c(
                                              "vs-button",
                                              {
                                                staticClass: "ml-auto mb-2",
                                                attrs: { color: "secondary" },
                                                on: { click: _vm.cancel },
                                              },
                                              [_vm._v("Fechar")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2044973237
                  ),
                },
                [
                  _c(
                    "template",
                    { slot: "thead" },
                    [
                      _c("vs-th", [_vm._v("Abertura")]),
                      _c("vs-th", [_vm._v("Saldo Abertura")]),
                      _c("vs-th", [_vm._v("Fechamento")]),
                      _c("vs-th", [_vm._v("Saldo Fechamento")]),
                      _c("vs-th", [_vm._v("Responsável")]),
                      _c("vs-th", [_vm._v("Ações")]),
                    ],
                    1
                  ),
                ],
                2
              )
            : _vm._e(),
          !_vm.cashiers || !_vm.cashiers.length
            ? _c(
                "vs-alert",
                {
                  staticClass: "mt-4 mb-4",
                  staticStyle: { height: "auto" },
                  attrs: {
                    active: true,
                    color: "warning",
                    "icon-pack": "feather",
                    icon: "icon-info",
                  },
                },
                [
                  _c("span", [
                    _vm._v(
                      "Nenhuma parcela em aberto para o período selecionado!"
                    ),
                  ]),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }